import React from "react";

import {Helmet} from "react-helmet";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  ModalBody, Modal
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import PuppyCarousel from "components/Other/PuppyCarousel.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function VR2023Page() {
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

const KikiImages = [
    { src: require("../../assets/img/dogs/litters/vr2023/kiki7.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/kiki6.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/kiki5.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/kiki4.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/kiki3.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/kiki2.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/kiki1.jpg")}
  ];

  const SophieImages = [
    { src: require("../../assets/img/dogs/litters/vr2023/sophie7.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/sophie6.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/sophie5.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/sophie4.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/sophie3.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/sophie2.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/sophie1.jpg")}
  ];

  const PonyoImages = [
    { src: require("../../assets/img/dogs/litters/vr2023/ponyo7.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/ponyo6.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/ponyo5.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/ponyo4.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/ponyo3.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/ponyo2.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/ponyo1.jpg")}
  ];

  const ChihiroImages = [
    { src: require("../../assets/img/dogs/litters/vr2023/chihiro7.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/chihiro6.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/chihiro5.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/chihiro4.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/chihiro3.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/chihiro2.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/chihiro1.jpg")}
  ];

  const HakuImages = [
    { src: require("../../assets/img/dogs/litters/vr2023/haku7.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/haku6.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/haku5.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/haku4.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/haku3.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/haku2.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/haku1.jpg")}
  ];

  const HowlImages = [
    { src: require("../../assets/img/dogs/litters/vr2023/howl7.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/howl6.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/howl5.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/howl4.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/howl3.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/howl2.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/howl1.jpg")}
  ];

  const TotoroImages = [
    { src: require("../../assets/img/dogs/litters/vr2023/totoro7.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/totoro6.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/totoro5.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/totoro4.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/totoro3.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/totoro2.jpg")},
    { src: require("../../assets/img/dogs/litters/vr2023/totoro1.jpg")}
  ];

  return (
      <>
        <MainNavbar />
        <div className="wrapper">
          <LandingPageHeader bg="vr2023" />
        <div className="back-nav">
          <a href="../litters"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Litters</a>
        </div>
          <div className="section section-about-us">
            <Container>
              <div className="separator separator-primary">
                 <Row>
                  <Col md="5">
                    <a onClick={() => setModal1(true)}>
                      <img
                          alt="..."
                          className="img-fluid img-raised"
                          src={require("../../assets/img/dogs/litters/vr2023/announcement.jpg")}
                          onClick={() => setModal1(false)}
                        >
                      </img>
                    </a>
                    <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                      maxWidth: "900px"
                    }}>
                      <div className="modal-header justify-content-center">
                        <button
                          className="close"
                          type="button"
                          onClick={() => setModal1(false)}
                        >
                          <i className="now-ui-icons ui-1_simple-remove"></i>
                        </button>
                        <h4 className="title title-up"></h4>
                      </div>
                      <ModalBody>
                        <img
                          alt="..."
                          src={require("../../assets/img/dogs/litters/vr2023/announcement.jpg")}
                        ></img>
                      </ModalBody>
                    </Modal>
                  </Col>
                  <Col md="7">
                    <h2 className="title pt-0">Rip x Venus 2023</h2>
                    <p>Rip is a well known dog in the flyball community. He's been on two winning multibreed teams with a personal best of 3.63. He has many offspring showing similar athletic talents in flyball and agility currently. Like Venus, he is very friendly with people, tolerant of other dogs, and has no environmental sensitivities. I'm expecting this breeding to produce friendly, high drive puppies who will thrive in experienced sport homes or with intermediate level handlers who are looking for a competitive dog. Rip competed in flyball and herding. He is now 13 years old, healthy, and retired from sports. Venus competes in flyball, herding, and dock diving. Preference will be for agility and/or flyball homes.</p>
                    <p>Rip is a carrier of CEA, but clear of all other testable diseases including EAOD. Venus is clear of all testable diseases including EAOD. They both have been cleared of hip dysplasia and eye issues. Venus has also been cleared of elbow dysplasia, shoulder OCD, and heart issues. <a href="https://ofa.org/advanced-search/?appnum=1543452" target="blank">Rip's OFA link</a> with offspring's results. Rip is 21" tall and 45lbs. Venus is 18" tall and 30lbs. There is a possibility for smooth or rough coats. Puppies will be registered with AKC. BAER and CAER testing will be done before the puppies go home.</p>
                    <p>For more information about Venus, view her individual page. For more information on Rip, contact Erin Rakosky or reach out to me if you would like to learn about this cross.</p> 
                  </Col>
                </Row>
                <Row className="pt-5">
                  <Col md="3">
                    <a className="dogs-link">
                      <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/profile-rip.jpg")}
                      ></img>
                      <div className="dogs-name"><span>Rip</span></div>
                    </a>
                    <a href="/dogs/venus" className="dogs-link">
                      <img
                      alt="..."
                      className="img-fluid pt-5"
                      src={require("../../assets/img/dogs/profile-venus2.jpg")}
                    ></img>
                      <div className="dogs-name"><span>Venus</span></div>
                    </a>
                  </Col>
                  <Col md="9" className="align-middle">
                    <img
                        alt="..."
                        className="img-fluid pt-5"
                        src={require("../../assets/img/dogs/litters/vr2023/pedigree.jpg")}
                        onClick={() => setModal2(false)}
                      >
                    </img>
                    <a href="https://bc-db.ru/en/pets/55925/pedigree" target="_blank">View pedigree</a>
                  </Col>
                </Row>  
              </div>
            </Container>
            <Container>
              <h2 className="title text-center pt-5">Videos of Venus</h2>
              <div className="team">
                <Row>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/PEZiEg6K7_U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                  </Col>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/a-rC0b1lkhA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                  </Col>
                </Row>
              </div>  

              <h3 className="title text-center">Venus' Half Siblings</h3>
              <div className="team pb-5">
                <Row>
                  <Col md="6" className="text-center">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100004649957922%2Fvideos%2F619954396198539%2F&show_text=false&width=560&t=0" width="100%" height="314" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share; fullscreen"></iframe>
                    <p>W Ei Ji & Serina (father: Rafter W Flank)</p>
                  </Col>
                  <Col md="6" className="text-center">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/SsPH2NmyoHQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                    <p>Grace Heck & Ember (mother: Cindy)</p>
                  </Col>
                </Row>
              </div>  

              <h2 className="title text-center pt-5">Videos of Rip</h2>
              <div className="team">
                <Row>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/FLq8oAcL3lM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                  </Col>
                  <Col md="6">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/nkXsiEWzzok" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                  </Col>
                </Row>
              </div>  

              <h3 className="title text-center">Rip's Offspring</h3>
              <div className="team">
                <Row>
                  <Col md="6" className="text-center"> 
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/xBLhzM6EpFs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                    <p>Classic & Stacie (Rip x Hallelujah)</p>
                  </Col>
                  <Col md="6" className="text-center">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/3hBJzTm6Lnw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                    <p>Reef & Erin (Rip x Cyclone)</p>
                  </Col>
                </Row>
              </div>  
              <div className="team text-center pt-5">
                <p>More videos of siblings/parents/offspring doing sports are available through messages.</p> 
              </div>  
            </Container>
          </div>
          <div className="section section-dog text-center">
            <div
              className="image-fullw section-sep-image"
              style={{
                backgroundImage: "url(" + require("../../assets/img/dogs/litters/vr2023/header2.jpg") + ")"
              }}
            ><div className="wrapper">

            </div>
            </div>
          </div>
          <div className="wrapper">
            <Container>
              <h2 className="title text-center">The Litter</h2>
                <div className="team text-center">
                  <Row>
                    <Col md="3">
                    </Col>
                    <Col md="6">
                      <p>Venus had an easy labor and gave birth to 7 puppies on May 13th, 2023. The litter theme was Studio Ghibli movie characters.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h4 className="title">Ricochet</h4>
                      <p className="registered-name text-primary">Endgame’s Shoot for the Stars</p>
                      <PuppyCarousel items={KikiImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Kiki</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female</div>
                        <div><span class="badge badge-neutral">Owner:</span> Lee-Ann Carr</div>
                        <div><span class="badge badge-neutral">Location:</span> Alberta</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Asher</h4>
                      <p className="registered-name text-primary">Endgame’s Shadow in the Ember</p>
                      <PuppyCarousel items={TotoroImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Totoro</div>
                        <div><span class="badge badge-neutral">Stats:</span> Male</div>
                        <div><span class="badge badge-neutral">Owner:</span> Erin Jenkins</div>
                        <div><span class="badge badge-neutral">Location:</span> SC</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Cool</h4>
                      <p className="registered-name text-primary">Endgame’s Super Cool</p>
                      <PuppyCarousel items={SophieImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Sophie</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female</div>
                        <div><span class="badge badge-neutral">Owner:</span> Monica Bush</div>
                        <div><span class="badge badge-neutral">Location:</span> AZ</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h4 className="title">Decoy</h4>
                      <p className="registered-name text-primary">Endgame’s Lure Them In</p>
                      <PuppyCarousel items={HakuImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Haku</div>
                        <div><span class="badge badge-neutral">Stats:</span> Male</div>
                        <div><span class="badge badge-neutral">Owner:</span> Meghan Reitsma</div>
                        <div><span class="badge badge-neutral">Location:</span> Ontario</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Squeeze</h4>
                      <p className="registered-name text-primary">Endgame’s Fresh Squeeze</p>
                      <PuppyCarousel items={PonyoImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Ponyo</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female</div>
                        <div><span class="badge badge-neutral">Owner:</span> M Nicole Fischer</div>
                        <div><span class="badge badge-neutral">Location:</span> PA</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <h4 className="title">Seeker</h4>
                      <p className="registered-name text-primary">Endgame’s Source of Magic</p>
                      <PuppyCarousel items={HowlImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Howl</div>
                        <div><span class="badge badge-neutral">Stats:</span> Male</div>
                        <div><span class="badge badge-neutral">Owner:</span> Karah Gerelus</div>
                        <div><span class="badge badge-neutral">Location:</span> Alberta</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h4 className="title">Shea</h4>
                      <p className="registered-name text-primary">Endgame’s It’s Outta Here</p>
                      <PuppyCarousel items={ChihiroImages} />
                      <div className="mt-3 blockquote text-left">
                        <div><span class="badge badge-neutral">Litter name:</span> Chihiro</div>
                        <div><span class="badge badge-neutral">Stats:</span> Female</div>
                        <div><span class="badge badge-neutral">Owner:</span> Theresa Deutsch</div>
                        <div><span class="badge badge-neutral">Location:</span> VA</div>
                        <div><span class="badge badge-neutral">BAER:</span> Normal</div>
                        <div><span class="badge badge-neutral">CAER:</span> Normal</div>
                      </div>
                    </Col>
                    <Col md="4">
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          <DefaultFooter />
        </div>
      </>
  );
}

export default VR2023Page;
